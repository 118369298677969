import React, { useEffect, useRef, useState } from "react";
import {
  Hero,
  Enroll,
  Explore,
  VideoRow,
  ExtraCurricular,
  QuestionAnswerVideo,
} from "./components";
import ConfigurationContext from "contexts/ConfigurationContext";
import apiRequest from "api/apiRequest";
import { Carousel } from "../../components";
import urls from "../../api/urls";
import { ScriptPages } from "../../constants/scriptsPage";

export const ManageHome = () => {
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const [contents, setContents] = React.useState([]);
  const [loaded, setLoaded] = React.useState(false);
  const [script, setScript] = useState(null);
  const scriptRef = useRef(null);

  useEffect(() => {
    if (script) {
      setTimeout(() => {
        const scriptTag = document.createElement("script");
        scriptTag.src = script.script;
        scriptTag.async = true;

        if (scriptRef.current) {
          scriptRef?.current?.appendChild(scriptTag);
        }
      }, 1000);
    }
  }, [script, script?.script]);

  useEffect(() => {
    const fetch = async () => {
      const response = await apiRequest({
        method: "get",
        url: `${urls.scripts}?page=${ScriptPages.home}`,
      });

      setScript(response);
    };
    fetch();
  }, []);

  const loadContent = async () => {
    try {
      const response = await apiRequest({
        method: "get",
        url: "homepage-content",
      });
      setContents(response);
      setLoaded(true);
    } catch (error) {
      console.log(error);
    }
  };

  useEffect(() => {
    loadContent();
  }, []);

  const getContent = location => {
    const content = contents.find(content => content.location === location);
    return content ? content.content : false;
  };
  if (!loaded) return [];
  return (
    <React.Fragment>
      <Carousel />

      {isFeatureEnabled("home_hero") && (
        <div>
          {getContent("hero") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("hero") }}></div>
          ) : (
            <Hero />
          )}
        </div>
      )}
      {isFeatureEnabled("home_questions") && (
        <div>
          {getContent("questions") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("questions") }}></div>
          ) : (
            <VideoRow />
          )}
        </div>
      )}
      {isFeatureEnabled("home_students_videos") && (
        <div>
          {getContent("students_videos") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("students_videos") }}></div>
          ) : (
            <QuestionAnswerVideo />
          )}
        </div>
      )}
      {isFeatureEnabled("home_ambassadors") && (
        <div>
          {getContent("ambassadors") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("ambassadors") }}></div>
          ) : (
            <ExtraCurricular />
          )}
        </div>
      )}
      {isFeatureEnabled("home_explore") && (
        <div>
          {getContent("explore") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("explore") }}></div>
          ) : (
            <Explore />
          )}
        </div>
      )}
      {isFeatureEnabled("home_enroll") && (
        <div>
          {getContent("enroll") ? (
            <div dangerouslySetInnerHTML={{ __html: getContent("enroll") }}></div>
          ) : (
            <Enroll />
          )}
        </div>
      )}
      {script && <div ref={scriptRef} dangerouslySetInnerHTML={{ __html: script.html }} />}
      {/*<SocialMediaPosts />*/}
    </React.Fragment>
  );
};

export default ManageHome;
