import { Carousel as ResponsiveCarousel } from "react-responsive-carousel";
import "react-responsive-carousel/lib/styles/carousel.min.css";
import apiRequest from "../../api/apiRequest";
import urls from "../../api/urls";
import { useEffect, useState } from "react";
import "./Carousel.css";
import { useHistory } from "react-router-dom";
import { getElementSize } from "../PageBuilder/utils/numToMeasurement";

export const Carousel = () => {
  const history = useHistory();

  const [isLoading, setIsLoading] = useState(true);
  const [carousel, setCarousel] = useState();

  useEffect(() => {
    fetchCarousel();
  }, []);

  const fetchCarousel = async () => {
    try {
      const response = await apiRequest({
        method: "GET",
        url: `${urls.carousel}?type=carousel`,
      });

      setCarousel(response.data);
    } catch (error) {
      console.log(error);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    const list = document.querySelectorAll(".slider li.slide");

    if (list.length > 0) {
      list.forEach(li => {
        li.style.minHeight = carousel?.carouselConfigurator.minHeight
          ? `${carousel?.carouselConfigurator.minHeight}px`
          : "150px";
        li.style.maxHeight = carousel?.carouselConfigurator.maxHeight
          ? `${carousel?.carouselConfigurator.maxHeight}px`
          : "600px";
      });
    }
  });

  if (isLoading || !Boolean(carousel?.isActive)) {
    return null;
  }

  const getLegend = labelConfigurator => {
    let bg = undefined;
    if (labelConfigurator.background) {
      const { background } = labelConfigurator;
      bg = `rgba(${parseInt(background.slice(1, 3), 16)}, ${parseInt(background.slice(3, 5), 16)}, ${parseInt(background.slice(5, 7), 16)}, ${labelConfigurator.opacity}%)`;
    }
    return (
      <div
        onClick={e => e.stopPropagation()}
        className="legend custom-legend"
        style={{
          bottom: `${labelConfigurator.y}%` || 0,
          left: `${labelConfigurator.x}%` || 0,
          background: bg,
          color: labelConfigurator.color,
          borderWidth: Number(labelConfigurator.borderWidth),
          borderColor: labelConfigurator.borderColor,
          borderStyle: labelConfigurator.borderStyle,
          minWidth: `${labelConfigurator.minWidth}px`,
          padding: `${labelConfigurator.upperPadding || 0}px ${labelConfigurator.sidePadding || 0}px`,
          textAlign: labelConfigurator?.textAlign,
          borderRadius: `${labelConfigurator.borderRadius}px`,
          fontSize: `max(${getElementSize(labelConfigurator.minFontSize)}, ${getElementSize(labelConfigurator.fontSize)})`,
        }}
      >
        {labelConfigurator.text}
      </div>
    );
  };

  const navigate = url => {
    if (!url) return;

    if (url.includes("http") || url.includes("https")) {
      return window.open(url, "_blank");
    }

    return history.push(url);
  };

  return (
    <ResponsiveCarousel
      autoFocus
      useKeyboardArrows
      autoPlay={carousel?.carouselConfigurator?.autoPlay}
      interval={+carousel?.carouselConfigurator?.interval * 1000}
      infiniteLoop={carousel?.carouselConfigurator?.infiniteLoop}
      showThumbs={false}
      showIndicators
      stopOnHover
      swipeable
      renderArrowNext={onClickNext => (
        <button
          type="button"
          aria-label="next slide / item"
          className="control-arrow control-next carousel-action-buttons left"
          onClick={onClickNext}
        />
      )}
      renderArrowPrev={onClickNext => (
        <button
          type="button"
          aria-label="next slide / item"
          className="control-arrow control-prev carousel-action-buttons right"
          onClick={onClickNext}
        />
      )}
    >
      {carousel.images.map((slide, index) => (
        <div
          style={{ cursor: slide?.labelConfigurator?.url ? "pointer" : "default" }}
          key={carousel.id}
          className="carousel-container"
          onClick={() => navigate(slide.labelConfigurator.url)}
        >
          <img src={slide.image} alt={slide?.alt || `slide-${index}`} />
          {slide?.labelConfigurator && getLegend(slide.labelConfigurator)}
        </div>
      ))}
    </ResponsiveCarousel>
  );
};
