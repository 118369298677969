import React, { useContext } from "react";
import Header from "./components/Header";
import Navbar from "./components/Navbar";
import Footer from "./components/Footer";
import Sidebar from "./components/Sidebar";
import ConfigurationContext from "../../contexts/ConfigurationContext";

export const Layout = props => {
  const { children } = props || {};
  const { configurations } = useContext(ConfigurationContext);

  const [isSidebarOpen, setSidebarOpen] = React.useState(false);

  const handleSidebarToggle = () => setSidebarOpen(!isSidebarOpen);

  return (
    <React.Fragment>
      {configurations.show_header && <Header onToggle={handleSidebarToggle} />}
      <Navbar />
      <main>{children}</main>
      {configurations.show_footer && <Footer />}
      <Sidebar isOpen={isSidebarOpen} onClose={handleSidebarToggle} />
    </React.Fragment>
  );
};

export default Layout;
