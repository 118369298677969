const urls = {
  baseUrl: process.env.REACT_APP_BASE_URL,
  programId: () => {
    const domain = window.location.host;
    const hostname = window.location.hostname;
    const isAdminUrl = /^.*?\.admin\..*/.test(domain);
    if (hostname === "localhost" || hostname === "think-12-1aa8a.web.app") {
      return process.env.REACT_APP_DEV_PROGRAM_DOMAIN;
    } else if (isAdminUrl) {
      return domain.replace(/\.admin/, "");
    } else {
      if (domain.includes(".staging")) return domain.replace(/\.staging/gi, "");
      return domain;
    }
  },
  programAccess: "program/active",
  configurations: "/configurations",
  activities: "/activities",
  preferences: "/preferences",
  userEnabledPreferences: "/users",
  familyPreferences: "/families/:family_id",
  qa: "/qa-content",
  // articles: '/article-content',
  articles: "/articles",
  articleDetails: "/article-content",
  articlesCategories: "/articles/categories",
  outcomeLogos: "/outcome-logos",
  outcomes: "/outcomes",
  ambassadors: "/ambassador-content",
  family: "/families",
  families: "/families",
  login: "/tokens",
  signUp: "/users",
  userProfile: "/users",
  updateUserProfile: "/users",
  changePassword: "/users/me/password",
  forgotPassword: "/recoveries",
  resetPassword: "/recoveries/verifications",
  updateProfileImage: "/images",
  dialogues: "/dialogues",
  pages: "/pages/page/",
  carousel: "carousel/byProgramId",
  carouselById: "carousel/",
  facebookPosts: "facebook/posts",
  groups: "groups",
  clubs: "clubs",
  announcements: "/announcements/active",
  scripts: "/3rd-parties",

  isProduction: function () {
    // return true;
    const program = this.programId();
    return (
      program === "app.think-12.com" ||
      program === "app.staging.think-12.com" ||
      program === "delbarton.think-12.com" ||
      program === "ssargwas.think-12.com"
    );
  },
  getApiDomain: function () {
    let str = process.env.REACT_APP_BASE_URL;
    if (!str) {
      return "app.think-12.com";
    }
    const url = new URL(str);
    let result = url.protocol + "//" + url.hostname;
    if (url.port) {
      result += ":" + url.port;
    }
    return result;
  },
};

export default urls;
