import React, { useEffect, useState } from "react";
import Box from "@mui/material/Box";
import apiRequest from "api/apiRequest";
// import urls from 'api/urls';
import { StaffMoreAboutUs } from "./components/StaffMoreAboutUs/StaffMoreAboutUs";
import { useHistory, useParams } from "react-router-dom";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import ConfigurationContext from "contexts/ConfigurationContext";
import { StaffAboutAndCommitment } from "./components/StaffAboutAndCommitment/StaffAboutAndCommitment";
import { StaffProfileActivities } from "./components/StaffProfileActivities/StaffProfileActivities";
import { StaffAboutMedia } from "./components/StaffAboutMedia";
import { StaffReviews } from "./components/StaffReviews";
import StaffProfileDetails from "./components/StaffProfileDetails/StaffProfileDetails";
import { ROUTES } from "../../../constants/routes";
import { Status } from "../../../api/Codes";

export const StaffDirectoryDetails = () => {
  const { type, staffId } = useParams();
  const [profile, setProfile] = useState(null);
  const [isLoading, setIsLoading] = useState(true);
  const { isFeatureEnabled } = React.useContext(ConfigurationContext);
  const history = useHistory();

  useEffect(() => {
    apiRequest({
      url: `/client-staff/${staffId}`,
    })
      .then(resp => {
        setProfile(resp.data);
        setIsLoading(false);
      })
      .catch(e => {
        if (e.status === Status.NotFound) {
          history.push(ROUTES.NOT_FOUND);
        }
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <React.Fragment>
      {isLoading ? (
        <LoadingIndicator />
      ) : (
        <React.Fragment>
          <Box p={{ xs: 2 }}>
            <StaffProfileDetails profile={profile} type={type} />
            <StaffAboutAndCommitment profile={profile} type={type} />

            {isFeatureEnabled("family_activities") && (
              <StaffProfileActivities profile={profile} type={type} />
            )}
            <StaffAboutMedia profile={profile} />
          </Box>
          <StaffMoreAboutUs profile={profile} type={type} />
          <StaffReviews profile={profile} type={type} />
        </React.Fragment>
      )}
    </React.Fragment>
  );
};

export default StaffDirectoryDetails;
