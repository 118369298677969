import React from "react";
import { Link } from "react-router-dom";
import COLORS from "constants/colors";
import Box from "@mui/system/Box";
import Button from "@mui/material/Button";
import Divider from "@mui/material/Divider";
import Container from "@mui/system/Container";
import Typography from "@mui/material/Typography";
import Check from "./components/Check";
import { useEffect } from "react";
import { useState } from "react";
import AuthContext from "contexts/AuthContext";
import ProfileContext from "contexts/ProfileContext";
import LoadingIndicator from "components/Utils/LoadingIndicator";
import apiRequest from "api/apiRequest";
import urls from "api/urls";
import { CircularProgress } from "@mui/material";
import { ROUTES } from "constants/routes";
import { useTheme } from "@mui/material/styles";

export const Preferences = props => {
  const [preferences, setPreferences] = useState([]);
  const [userPreferences, setUserPreferences] = useState([]);
  const { user } = React.useContext(AuthContext);
  const { savePreferences, loadingButton } = React.useContext(ProfileContext);
  const theme = useTheme();

  const [isLoading, setIsLoading] = useState(true);
  const [checkboxState, setCheckboxState] = useState({});

  useEffect(() => {
    fetchPreferences();
  }, [user]);

  const handleOnChange = id => {
    setCheckboxState({ ...checkboxState, [id]: !checkboxState[id] });
  };

  const saveChanges = () => {
    let preferencesArray = [];
    Object.keys(checkboxState).forEach(key => {
      if (checkboxState[key]) {
        preferencesArray.push(parseInt(key));
      }
    });
    savePreferences(user, preferencesArray);
  };

  const fetchPreferences = async () => {
    try {
      setIsLoading(true);
      const responsePreferences = await doFetchPreferences();
      const responseUserPreferences = await doFetchUserPreferences();

      if (responseUserPreferences.length) {
        const newCheckBoxState = {};
        for (let i = 0; i < responseUserPreferences.length; i++) {
          const up = responseUserPreferences[i];
          newCheckBoxState[up.id] = true;
        }
        setCheckboxState(newCheckBoxState);
      }
      setPreferences(responsePreferences);
      setUserPreferences(responseUserPreferences);
    } catch {
    } finally {
      setIsLoading(false);
    }
  };

  const doFetchPreferences = async () =>
    await apiRequest({
      method: "get",
      url: `${urls.preferences}?filter[category][is]=Contact`,
    });

  const doFetchUserPreferences = async () =>
    await apiRequest({
      method: "get",
      url: `${urls.userEnabledPreferences}/me${urls.preferences}`,
    });

  if (isLoading) {
    return <LoadingIndicator />;
  }

  return (
    <React.Fragment>
      <Container>
        <Box>
          <Typography variant="h5" fontWeight="bold">
            Preferences
          </Typography>
        </Box>
        <Box mt={3} mb={2}>
          <Typography fontWeight="bold">Contact preferencesdddd</Typography>
        </Box>
        {preferences.map(pref => (
          <Check
            id={pref.id}
            disabled={pref.is_verified_only ? "disabled" : null}
            text={pref.description}
            onChange={() => handleOnChange(pref.id)}
            checked={checkboxState[pref.id]}
          />
        ))}
        <Box my={3}>
          <Divider />
        </Box>
        <Box mt={2} sx={{ background: `${theme.palette.primary.main}40`, borderRadius: "10px" }}>
          <Typography color={COLORS.secondary.main} sx={{ p: 3 }} variant="body1">
            *Note: Only verified enrolled families may connect with enrolled families on this
            platform. Learn more about our privacy policy and verification for enrolled families{" "}
            <Link style={{ color: COLORS.primary.main }} to={ROUTES.PRIVACY_POLICY}>
              here.
            </Link>
          </Typography>
        </Box>
        <Box mb={10} mt={5} display="flex" justifyContent="flex-end">
          <Button
            sx={{ width: "233px", height: "60px" }}
            className="MuiButton-profile"
            variant="contained"
            color="secondary"
            disabled={loadingButton ? true : false}
            onClick={saveChanges}
          >
            {loadingButton ? <CircularProgress style={{ color: "white" }} /> : "Save Changes"}
          </Button>
        </Box>
      </Container>
    </React.Fragment>
  );
};

export default Preferences;
